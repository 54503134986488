import React from 'react'

const Navbar = () => {
  return (
    <nav>
      {/* <a href='#'>About</a>
      <a href='#' className='selected'>
        Portfolio
      </a>
      <a href='#'>IG</a> */}
      <a href='https://www.facebook.com/ehhahah'>facebook</a>
      <a href='https://instagram.com/ehhhhahah'>instagram</a>
      <a href='https://www.youtube.com/c/ehhhahah'>youtube</a>
      <a href='https://twitter.com/ehh_hahah'>twitter</a>
      <a href='https://soundcloud.com/ehhahah'>soundcloud</a>
      <a href='https://ehhhahah.bandcamp.com/'>bandcamp</a>
      <a href='mailto:ehhhahah@gmail.com'>e-mail</a>
      {/* <p className='cookie'>this site uses cookies</p> */}
    </nav>
  )
}

export default Navbar
