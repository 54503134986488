import React, { useState, useEffect } from 'react'
import './../../App.css'

const ScrollLogo = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scale = 1 + 8 * Math.sin(scrollPosition / 7000) // Adjust scale speed
  const rotate = scrollPosition / 100 // Adjust rotation speed
  const opacity = 1 - scrollPosition / 5000 // Adjust fade-out speed
  //   const blur = scrollPosition / 1000 // Adjust blur speed

  return (
    <div className='scroll-logo'>
      <h1
        className='logo'
        style={{
          transform: `scale(${scale})`,
          //   opacity: opacity > 0 ? opacity : 0, // Avoid negative opacity
          rotate: `${rotate}deg`
          //   filter: `blur(${blur}px)`
        }}>
        ehh hahah
      </h1>
    </div>
  )
}

export default ScrollLogo
