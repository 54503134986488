import About from './components/About'
import './App.css'
import Navbar from './components/Navbar'
import Portfolio from './components/Portfolio'
import ScrollLogo from './components/widgets/scrollLogo'

function App() {
  return (
    <div className='App'>
      <Navbar />
      <About />
      <ScrollLogo />
      <Portfolio />
    </div>
  )
}

export default App
