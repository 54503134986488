import React from 'react'
import enspanies from './../db/portfolio.json'
import { getTypeTag } from './widgets/tags.js'
import './../filters.css'

export default class Portfolio extends React.Component {
  state = {
    enspanies: enspanies,
    detailsRange: 100,
    collaboratorFilter: '',
    cityFilter: '',
    typeFilter: ''
  }

  // BASE

  generateListByYears() {
    // get enspanies grouped per year
    const displayList = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014'].map(
      (year) => [year, this.getEnspanies(year)]
    )

    // generate list to render
    let toReturn = displayList.map(
      ([year, enspanies], index) =>
        enspanies.length > 0 && (
          <div className='yearGroup' key={year}>
            <div className='caption-top sticky'>
              <h2 id='year'>{year}</h2>
              {enspanies.length > 1 ? <p>{enspanies.length} elements</p> : ''}
            </div>
            <div className='yearElements'>{enspanies.map((enspany) => this.displayEnspanyTable(enspany))}</div>
          </div>
        )
    )

    // return
    return toReturn.some((e) => e) ? (
      toReturn
    ) : (
      <div>
        <h2>Empty results!</h2>
        <p>spany different filter settings</p>
      </div>
    )
  }

  // FILTERING

  getAllowedByDetails() {
    let allowedTypes = ['album']
    if (this.state.detailsRange < 2) {
      return []
    }
    if (this.state.detailsRange > 20) {
      allowedTypes.push('ep')
    }
    if (this.state.detailsRange > 60) {
      allowedTypes.push('single', 'edu')
    }
    return allowedTypes
  }

  getAllowedByCollaborator(enspany, collaborator) {
    return enspany.with && enspany.with.length > 0 && enspany.with.includes(collaborator)
  }

  getAllowedCity(enspany, city) {
    return enspany.city && enspany.city.length > 0 && enspany.city.includes(city)
  }

  getAllowedByType(enspany, type) {
    return enspany.type && enspany.type.length > 0 && enspany.type.includes(type)
  }

  getEnspanies(year) {
    let toReturn = this.state.enspanies[year]

    // details priority
    if (this.state.detailsRange < 100) {
      toReturn = toReturn
        // check if enspany is allowed by lower bound
        .filter(
          (enspany) =>
            this.getAllowedByDetails().includes(enspany.type) ||
            (enspany.fixed_priority_min && enspany.fixed_priority_min <= this.state.detailsRange)
        )
        // check if enspany is excluded by upper bound
        .filter((enspany) => !(enspany.fixed_priority_max && enspany.fixed_priority_max >= this.state.detailsRange))
    }
    // collaborators filter
    if (this.state.collaboratorFilter) {
      toReturn = toReturn.filter((enspany) => this.getAllowedByCollaborator(enspany, this.state.collaboratorFilter))
    }

    // city filter
    if (this.state.cityFilter) {
      toReturn = toReturn.filter((enspany) => this.getAllowedCity(enspany, this.state.cityFilter))
    }

    // type filter
    if (this.state.typeFilter) {
      toReturn = toReturn.filter((enspany) => this.getAllowedByType(enspany, this.state.typeFilter))
    }

    return toReturn
  }

  // DISPLAYING

  displayEnspanyTable(element) {
    return (
      <div className={`element ${element.type === 'album' ? 'special' : ''}`}>
        <div className='elementText' key={element.id || element.title}>
          <div className='elementRowOne'>
            <div className='elementType'>{element.type && getTypeTag(element.type)}</div>
            <div className='tableTitle'>
              <h3>{element.title}</h3>
            </div>
          </div>
          <div className='elementRowTwo'>
            {element.link && <div className='elementLink'>{this.displayEnspanyLink(element)}</div>}
            {element.place && element.city ? (
              <div className='elementPlace'>{element.place + ', ' + element.city}</div>
            ) : null}
            {element.with && element.with.length > 0 ? (
              <div className='elementCollabs'>
                {element.with.map((collaborator) => (
                  <div className='elementCollab'>*{collaborator + ' '}</div>
                ))}
              </div>
            ) : null}
            {element.description && element.description.length > 0 ? (
              <details className='spanDesc'>
                <summary className='titleSummary'>
                  <div>Read more ⬎</div>
                </summary>
                {element.date && <div className='elementDate'>{element.date}</div>}
                {element.description.split('\n').map((line) => (
                  <p className='content'>{line}</p>
                ))}
              </details>
            ) : null}
          </div>
          <div className='elementRowThree'>
            {/* <div className='tablePic'>
              {element.img ? (
                <img
                  className=' object-contain inline min-h-0 max-h-20'
                  src={require('./../db/imgs/' + element.img)}
                  alt={element.title}
                  width={100}
                />
              ) : null}
            </div> */}
          </div>
        </div>
        <div className='hover-image'>
          {element.img ? <img src={require('./../db/imgs/' + element.img)} alt={element.title} /> : null}
        </div>
      </div>
    )
  }

  displayEnspanyLink(enspany) {
    return enspany.link ? (
      <div className='aDiv'>
        <a href={enspany.link} target='_blank' rel='noreferrer'>
          Link
        </a>
      </div>
    ) : null
  }

  displayDetailsFilter() {
    return (
      <div className='filterOption'>
        <label className='block mb-2 text-sm font-medium'>Details range filter</label>
        <input
          type='range'
          min='0'
          max='100'
          value={this.state.detailsRange}
          className='p-2 w-full rounded-full cursor-pointer accent-emerald-700'
          onChange={(e) => this.setState({ detailsRange: e.target && e.target.value })}
        />
      </div>
    )
  }

  displayCollabFilter() {
    return (
      <div className='filterOption'>
        <label className='block mb-2 text-sm font-medium'>Collaborator</label>
        <select
          id='selectCollab'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg'
          onChange={(e) => this.setState({ collaboratorFilter: e.target && e.target.value })}
          defaultValue={''}>
          {/* TODO fill collaboratos - make it generate from file*/}
          <option value=''></option>
          <option disabled>Collaborators</option>
          <option value='Spalarnia'>Spalarnia</option>
          <option value='japanese.inhale'>japanese.inhale</option>
          <option disabled>Remixers</option>
          <option value='bela'>bela</option>
        </select>
        {this.displayClearButton({ collaboratorFilter: '' }, 'selectCollab')}{' '}
      </div>
    )
  }

  displayCityFilter() {
    return (
      <div className='filterOption'>
        <label className='block mb-2 text-sm font-medium'>City</label>
        <select
          id='selectCity'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg '
          onChange={(e) => this.setState({ cityFilter: e.target && e.target.value })}
          defaultValue=''>
          {/* TODO fill cities */}
          <option value=''></option>
          <option disabled>Polska</option>
          <option value='Kraków'>Kraków</option>
          <option value='Wrocław'>Wrocław</option>
          <option disabled>Za granicą</option>
          <option value='Paris'>Paris</option>
          <option value='Ostrava'>Ostrava</option>
        </select>
        {this.displayClearButton({ cityFilter: '' }, 'selectCity')}{' '}
      </div>
    )
  }

  displayTypeFilter() {
    return (
      <div className='filterOption'>
        <label className='block mb-2 text-sm font-medium'>Type</label>
        <select
          id='selectType'
          className='p-2 text-sm text-gray-900 border border-gray-300 rounded-lg '
          onChange={(e) => this.setState({ typeFilter: e.target && e.target.value })}
          defaultValue=''>
          {/* TODO fill types */}
          <option value=''></option>
          <option disabled>Releases</option>
          <option value='album'>Albums</option>
          <option value='ep'>EPs</option>
          <option value='single'>Singles</option>
          <option disabled>Other musical</option>
          <option value='live'>Lives</option>
          <option value='event'>Events</option>
          <option value='workshop'>Workshops</option>
          <option disabled>Art</option>
          <option value='exhibition'>Exhibitions</option>
          <option value='installation'>Installations</option>
          <option disabled>Others</option>
          <option value='website'>Websites</option>
          <option value='edu'>Education</option>
          <option value='interview'>Interviews</option>
        </select>
        {this.displayClearButton({ typeFilter: '' }, 'selectType')}
      </div>
    )
  }

  displayDisplayAllButton() {
    // TODO bug - jak sie kliknie to nie odznacza rzeczy w selectach
    return (
      <div>
        <button
          className='btn btn-error'
          onClick={() => this.setState({ detailsRange: 100, collaboratorFilter: '', cityFilter: '', typeFilter: '' })}>
          Remove filters
        </button>
      </div>
    )
  }

  displayClearButton(expectedState, elementId) {
    return (
      <button
        className='clearButton'
        onClick={() => {
          this.setState(expectedState)
          let el = document.getElementById(elementId)
          el.value = ''
        }}>
        clear selection
      </button>
    )
  }

  displayShowFilteringButton() {
    return (
      <button
        id='settingsButton'
        onClick={() => {
          document.getElementById('filterModal').toggleAttribute('open')
        }}>
        <h3>FILTERING</h3>
      </button>
    )
  }

  displayFilteringSection() {
    return (
      <details id='filterModal' className='myModal open'>
        <summary style={{ display: 'none' }}></summary>
        <div className='filters-panel'>
          <div className='flex flex-row space-x-5'>{this.displayDetailsFilter()}</div>
          {this.displayCollabFilter()}
          {this.displayTypeFilter()}
          {this.displayCityFilter()}
          <div className='apply-buttons'>
            {this.displayDisplayAllButton()}
            <button className='btn' onClick={() => document.getElementById('filterModal').removeAttribute('open')}>
              Hide
            </button>
          </div>
        </div>
      </details>
    )
  }

  render() {
    return (
      <div>
        {this.displayShowFilteringButton()}
        {this.displayFilteringSection()}
        {this.generateListByYears()}
      </div>
    )
  }
}
