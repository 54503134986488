import React from 'react'
import bios from '.././db/bios.json'

export default class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedBioId: Math.floor(Math.random() * Object.keys(bios).length)
    }
  }

  renderBio() {
    // return <p className='bio-text'>I am {bios[this.state.selectedBioId + 1]}</p>
    return bios[this.state.selectedBioId + 1].split('\n').map((line, ind) => (
      <p className='content'>
        {ind === 0 ? 'I am ' : ''}
        {line}
      </p>
    ))
  }

  render() {
    return (
      <div className='about-page'>
        <div className='pic-column'>
          <img src={require('.././db/imgs/zdjecie_dowod.jpg')} alt='passport face' className='bio-pic'></img>
          {/* <div className='social-medias'>
            <a href='https://www.facebook.com/ehhahah'>facebook</a>
            <a href='https://instagram.com/ehhhhahah'>instagram</a>
            <a href='https://www.youtube.com/c/ehhhahah'>youtube</a>
            <a href='https://twitter.com/ehh_hahah'>twitter</a>
            <a href='https://soundcloud.com/ehhahah'>soundcloud</a>
            <a href='https://ehhhahah.bandcamp.com/'>bandcamp</a>
            <a href='mailto:ehhhahah@gmail.com'>e-mail</a>
          </div> */}
        </div>
        <div>{this.renderBio()}</div>
      </div>
    )
  }
}
